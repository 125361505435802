.advert-title-container {
  display: flex;
  width: 100%;
  max-width: 500px;
  padding: 5px 0;
  color: #333333;
  font-size: 14px;
  & + .advert-title-container {
    border-top: 1px solid #dee2e6;
  }
  a.advert-title-advert {
    &:hover {
      text-decoration: none;
    }
  }
  .advert-title-campaign {
    font-size: 13px;
    // color: #000;
  }
  .advert-title-meta {
    font-size: 12px;
  }
  .advert-title-number {
    font-weight: bold;
    font-size: 14px;
  }
}
