$neutralised: #9b9b9b;
$live: #80d82c;
$reserved: #5fa1fd;
$deficit: #ff0000;

.allocation-state {
  .progress {
    height: 10px;
    .progress-bar {
      color: transparent;
      &.neutralised {
        background-color: $neutralised;
      }
      &.extended {
        background-color: $neutralised;
      }
      &.live {
        background-color: $live;
      }
      &.reserved {
        background-color: $reserved;
      }
      &.deficit,
      &.overspent {
        background-color: $deficit;
      }
    }
  }
}
