.module-navbar {
  padding: 0 1rem;
  font-size: 16px;
  background-color: #004677;
  .navbar-nav .nav-link {
    padding: 0.8rem 1rem;
    color: rgba(255, 255, 255, 0.7);
    &.active {
      color: #f5872a;

      background-color: #00355b;
    }
  }
  & + & {
    .navbar-nav .nav-link {
      padding: 0.6rem 1rem;
    }
    font-size: 15px;
    background-color: #00355b;
  }
}
