.agreement-invoices {
  .table {
    font-size: 14px;
    th,
    td {
      &.state {
        width: 130px;
      }
      &.number {
        width: 110px;
      }
      &.date {
        width: 120px;
        text-align: center;
        input {
          text-align: center;
        }
      }
      &.summ {
        width: 90px;
        input {
          text-align: right;
        }
      }
      &.tools {
        width: 40px;
        text-align: center;
      }
    }
    .form-group {
      margin-bottom: 0;
    }
  }
  .agreement-invoices-header {
    h4 {
      margin: auto 0;
    }
    display: flex;
    justify-content: space-between;
    .sums {
      display: flex;
      flex-direction: row;
      text-align: right;
      font-size: 14px;
      strong {
        margin-left: 5px;
      }
      .sm {
        font-size: 13px;
        color: #565656;
      }
      > div {
        margin-left: 15px;
      }
    }
  }

  .agreement-invoices-footer {
    margin-right: 27px;
  }

  .col-date {
    width: 120px;
  }
  .col-sum {
    width: 140px;
    input {
      text-align: right;
    }
  }
}
