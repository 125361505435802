.reservation-report-container {
  width: 100%;
  height: calc(100vh - 140px);
  .grid line {
    stroke: lightgrey;
    stroke-opacity: 0.6;
    shape-rendering: crispEdges;
  }
  shape-rendering: crispEdges;
  .grid path {
    stroke-width: 0;
  }
  position: relative;
  .advert {
    fill-opacity: 0.3;
    cursor: pointer;
    &:hover {
      fill-opacity: 0.6;
    }
  }
}
#reservation-tooltip {
  z-index: 101;
  width: 200px;
  .popover-header,
  .popover-body {
    padding-bottom: 0;
    font-size: 12px;
  }

  position: absolute;
  pointer-events: none;
}
