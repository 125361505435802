$primary: #007bff;
$secondary: #6c757d;
$success: #6fba27;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;

.state-badge {
  font-size: 11px;
  height: 11px;
  line-height: 1;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.28px;
  color: #565656;
  position: relative;
  display: inline-block;
  padding-left: 18px;
  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    content: '';
    width: 14px;
    height: 14px;

    border-radius: 50%;
  }
  &.primary {
    &::before {
      background-color: $primary;
      border: 3px solid lighten($primary, 30%);
    }
  }
  &.secondary {
    &::before {
      background-color: $secondary;
      border: 3px solid lighten($secondary, 30%);
    }
  }
  &.success {
    &::before {
      background-color: $success;
      border: 3px solid lighten($success, 30%);
    }
  }
  &.info {
    &::before {
      background-color: $info;
      border: 3px solid lighten($info, 30%);
    }
  }
  &.warning {
    &::before {
      background-color: $warning;
      border: 3px solid lighten($warning, 30%);
    }
  }
  &.danger {
    &::before {
      background-color: $danger;
      border: 3px solid lighten($danger, 30%);
    }
  }
}
