$neutralised: #9b9b9b;
$live: #80d82c;
$reserved: #5fa1fd;
$deficit: #ff0000;

.allocation-badge {
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.05em;
  .pill {
    color: #fff;
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    display: inline-block;
    padding: 0.25em 0.5em;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    margin-right: 5px;
  }
  &.neutralised .pill,
  &.extended .pill {
    background-color: $neutralised;
  }
  &.live .pill {
    background-color: $live;
  }
  &.reserved .pill {
    background-color: $reserved;
  }
  &.deficit .pill {
    background-color: $deficit;
  }
  &.overspent .pill {
    background-color: $deficit;
  }
}
