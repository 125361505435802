@import '~react-toastify/dist/ReactToastify.css';
// Override default variables before the import
// $body-bg: #000;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1536px,
  // 1140,
) !default;

$blue: #005794 !default;
$orange: #ba3200 !default;
$yellow: #ba8a00 !default;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

@import '~ffsdk/dist/ffsdk.css';

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  &.ReactModal__Body--open {
    overflow: hidden;
  }
}

.react-select__multi-value {
  width: 100%;
  justify-content: space-between;
  display: flex;
  border-radius: 3px;
  margin-top: 3px;
}
.form-inline .react-datepicker-wrapper .form-control {
  // width: 12ch;
}

.table-form {
  font-size: 14px;
  th {
    padding: 1px 0.5rem;
    width: 30%;
    text-align: left;
    font-weight: normal;
    color: #565656;
    vertical-align: middle;
    background-color: #f4f4f4;
  }
  .th {
    color: #565656;
    padding: 0.375rem 0.5rem;
  }

  td {
    padding: 1px;
    vertical-align: middle;
  }
  .form-control-plaintext {
    padding-left: 0.875rem;
  }
  .form-control,
  .form-control-plaintext {
    font-size: inherit;
  }

  .form-group {
    margin-bottom: 0;
  }
  .discount-field {
    width: 70px;
    input {
      text-align: right;
    }
  }
}

.text-line-through {
  text-decoration: line-through;
}

.form-inline.list-filter .form-group {
  margin-bottom: 1rem;
  .react-datepicker__input-container input {
    width: 100px;
  }
}

.table {
  font-size: 14px;
}
