.exposure-card {
  font-size: 14px;
  margin-bottom: 0.5rem;
  .list-group-item {
    padding: 0.25rem 0.5rem;
  }
  .card-header {
    padding: 0.5rem 0.5rem;
  }
  .vehicle-state {
    color: #fff;
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    display: inline-block;
    padding: 0.25em 0.5em;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    margin-right: 5px;

    &.neutralised {
      background-color: #9b9b9b;
    }
    &.live {
      background-color: #80d82c;
    }
  }
}
